import React, { useEffect, useRef, useState } from 'react';
import './Profile.css';
import axios from 'axios';
import { useSnackbarContext } from '../../../../ui/src/contextProviders';

function Profile() {
  const [designation, setDesignation] = useState('');
  const [qualification, setQualification] = useState('');
  const [fileName, setFileName] = useState('');
  const [jpgSignatureFile, setJpgSignatureFile] = useState();
  const BaseURL = window.config.servers.dicomWeb[0].wadoRoot;
  const snackBar = useSnackbarContext();
  const inputref = useRef();
  const containsSpecialChars = str => {
    const specialChars = /[`!@#$%^&*()+\=\[\]{};':"\\|,.<>\/?~]/;
    return specialChars.test(str);
  };

  const userId = sessionStorage.getItem('loggedinuser');

  const idtoken = JSON.parse(
    sessionStorage.getItem(
      'oidc.user:' +
        window.config.oidc[0].authority +
        ':' +
        window.config.oidc[0].client_id
    )
  );
  const bearerToken = 'bearer ' + idtoken.id_token;

  const submitHandler = e => {
    e.preventDefault();
    if (
      containsSpecialChars(designation) ||
      containsSpecialChars(qualification)
    ) {
      snackBar.show({
        message: 'Contains special charecters',
        type: 'warning',
      });
      return;
    }
    if (designation.trim().length === 0 || qualification.trim().length === 0) {
      snackBar.show({
        message: 'Empty field',
        type: 'warning',
      });
      inputref.current.value = null;
      return;
    } else if (fileName === '') {
      snackBar.show({
        message: 'Signature is not uploaded',
        type: 'warning',
      });
      inputref.current.value = null;
    } else {
      const url = `${BaseURL}signatureupload/`;
      const data = new FormData();
      data.append('file', jpgSignatureFile ? jpgSignatureFile : null);
      data.append('fileName', jpgSignatureFile ? jpgSignatureFile.name : '');

      axios
        .post(url, data, {
          params: {
            designation: designation,
            qualification: qualification,
            userId: userId,
          },
          headers: {
            'Content-Type': 'image/jpeg',
            Authorization: bearerToken,
          },
        })
        .then(res => {
          if (res.status === 200) {
            snackBar.show({
              message: 'Submitted',
              type: 'success',
            });
          }
        })
        .catch(err => console.error(err));
      inputref.current.value = null;
    }
  };
  const fileChangeHandler = () => {
    if (
      inputref.current.files[0].type === 'image/jpeg' ||
      inputref.current.files[0].type === 'image/jpg'
    ) {
      setJpgSignatureFile(inputref.current.files[0]);
      setFileName(inputref.current.files[0].name);
    } else {
      snackBar.show({
        message: 'Only jpeg/jpg is allowed',
        type: 'warning',
      });
      inputref.current.value = null;
      return;
    }
    };

  const fetchUserDetails = ()=>{
    const url = `${BaseURL}fetchuserdetails/`;

    axios.post(url,null,{
      headers:{Authorization:bearerToken},
      params:{userid:userId}
    })
    .then(res =>{
      if(res.status === 200){
        setDesignation(res.data.designation);
        setQualification(res.data.qualification);
        setFileName(res.data.fileName);
        console.log(res.data)
      }
    })
    .catch(err => console.error(err));
  };
  useEffect(()=>{fetchUserDetails()},[]);
  return (
    <>
      <form className='Profile-form' onSubmit={submitHandler} action="">
        <table className="profile-table">
          <tr>
            <th>Designation : </th>
            <td>
              {' '}
              <input
                type="text"
                maxLength={30}
                value={designation}
                onChange={e => {
                  setDesignation(e.target.value);
                }}
                placeholder="Max char (30)"
              />{' '}
            </td>
          </tr>

          <tr>
            <th>Qualification : </th>
            <td>
              {' '}
              <input
                type="text"
                maxLength={30}
                value={qualification}
                onChange={e => {
                  setQualification(e.target.value);
                }}
                placeholder="Max char (30)"
              />{' '}
            </td>
          </tr>

          <tr>
            <th>Signature : </th>
            <td>
              {' '}
              <input
                type="file"
                name=""
                id=""
                accept=".jpg"
                ref={inputref}
                onChange={fileChangeHandler}
              />{' '}
            </td>
          </tr>
        </table>

        <button className="submit-button" type="submit">
          Submit
        </button>
      </form>
    </>
  );
}

export default Profile;
