import React from 'react';
import WhiteLabelingContext from '../../context/WhiteLabelingContext';
import Navbar from './DropedownComponentNavbar';
import UserManagerContext from '../../context/UserManagerContext';
import AppContext from '../../context/AppContext';
import ConnectedHeader from '../../connectedComponents/ConnectedHeader';
import './DropedownComponent.css';

const DropedownHeader = props => {
  const { user } = props;
  return (
    <header>
      <WhiteLabelingContext.Consumer>
        {whiteLabeling => (
          <UserManagerContext.Consumer>
            {userManager => (
              <AppContext.Consumer>
                {appContext => (
                  <ConnectedHeader
                    linkText={
                      appContext.appConfig.showStudyList
                        ? 'Study List'
                        : undefined
                    }
                    linkPath={
                      appContext.appConfig.showStudyList ? '/' : undefined
                    }
                    userManager={userManager}
                  >
                    {whiteLabeling &&
                      whiteLabeling.createLogoComponentFn &&
                      whiteLabeling.createLogoComponentFn(React)}
                  </ConnectedHeader>
                )}
              </AppContext.Consumer>
            )}
          </UserManagerContext.Consumer>
        )}
      </WhiteLabelingContext.Consumer>
      <div className="nav-area">
        <Navbar />
      </div>
    </header>
  );
};
export default DropedownHeader;
