import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Thumbnail } from './Thumbnail.js';
import './StudyBrowser.styl';
import Icon from '../../elements/Icon/Icon.js';
import moment from 'moment';

function StudyBrowser(props) {
  const {
    is2dmprActive,
    studies,
    onThumbnailClick,
    onThumbnailDoubleClick,
    supportsDrag,
    showThumbnailProgressBar,
    patientStudies,
    primaryStudy,
    addStudyInstanceUids,
    studyMetadata,
    activeViewport,
    loadKeyImageDisplaySet,
  } = props;
  const [clickedStudy, setClickedStudy] = useState([primaryStudy]);
  const [activeButton, setActiveButton] = useState('Primary');
  const [displayingStudies, setDisplayingStudies] = useState([]);

  useEffect(() => {
    if (patientStudies.length > 0) {
      if (activeButton === 'All') {
        setDisplayingStudies(patientStudies);
        setClickedStudy([]);
      } else if (activeButton === 'Primary') {
        setDisplayingStudies([primaryStudy]);
        setClickedStudy([primaryStudy]);
      }
    }
  }, [activeButton, patientStudies, primaryStudy]);

  useEffect(() => {
    if (activeViewport)
      if (activeViewport.isKeyImage) {
        const study = studyMetadata.find(
          study => study.StudyInstanceUID === activeViewport.StudyInstanceUID
        );
        if (study) {
          if (study.keyUids.length <= 0) {
            onThumbnailClick(study.displaySets[0].displaySetInstanceUID);
          }
        }
      }
  }, [activeViewport, onThumbnailClick, studyMetadata]);

  return (
    <div className="study-browser">
      <div className="studyOptions">
        <div className="options">
          <button
            id="Primary"
            className={activeButton === 'Primary' ? 'active' : ''}
            onClick={() => {
              setActiveButton('Primary');
            }}
          >
            Primary
          </button>
          <button
            id="All"
            className={activeButton === 'All' ? 'active' : ''}
            onClick={() => {
              setActiveButton('All');
            }}
          >
            All
          </button>
        </div>
      </div>
      <div className="scrollable-study-thumbnails">
        {displayingStudies
          .sort((a, b) => (a.StudyDate > b.StudyDate ? -1 : 1))
          .map((patientStudy, patientIndex) => {
            if (patientStudy) {
              return (
                <div key={patientIndex}>
                  <div
                    className={
                      patientStudy === primaryStudy && activeButton === 'All'
                        ? 'StudyViewer PrimaryStudy'
                        : 'StudyViewer'
                    }
                    onClick={() => {
                      // adding studies to the clicked studies list while user clicking
                      if (
                        clickedStudy.find(
                          study =>
                            study.StudyInstanceUID ===
                            patientStudy.StudyInstanceUID
                        )
                      ) {
                        setClickedStudy([
                          ...clickedStudy.filter(
                            study =>
                              study.StudyInstanceUID !==
                              patientStudy.StudyInstanceUID
                          ),
                        ]);
                      } else {
                        setClickedStudy([...clickedStudy, patientStudy]);
                        addStudyInstanceUids(patientStudy.StudyInstanceUID);
                      }
                    }}
                  >
                    <div className="EachStudy">
                      <div className="date_series">
                        <div className="date">
                          {moment(patientStudy.StudyDate, 'YYYYMMDD').format(
                            'DD-MMM-YYYY'
                          )}
                        </div>
                        <div className="series">
                          <Icon name="copy" />
                          <span>
                            {patientStudy.numberOfStudyRelatedInstances}
                          </span>
                        </div>
                      </div>
                      <div className="modality_description">
                        <div className="modality">
                          {patientStudy.modalities}
                        </div>
                        <div className="description">
                          {patientStudy.StudyDescription}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    // className="scrollable-study-thumbnails"
                    style={
                      clickedStudy.find(
                        study =>
                          study.StudyInstanceUID ===
                          patientStudy.StudyInstanceUID
                      ) &&
                      studies.find(
                        study =>
                          study.StudyInstanceUID ===
                          patientStudy.StudyInstanceUID
                      ) && { marginTop: '10px' }
                    }
                  >
                    {/* Displaying the thumbnails corresponding to the selected studies */}
                    {studies
                      .map((study, studyIndex) => {
                        const { StudyInstanceUID } = study;
                        if (
                          clickedStudy.find(
                            clicked =>
                              clicked.StudyInstanceUID === StudyInstanceUID
                          ) &&
                          StudyInstanceUID === patientStudy.StudyInstanceUID
                        ) {
                          if (study.thumbnails && study.thumbnails.length > 0) {
                            if (
                              study.thumbnails.find(item => item.isKeyImage)
                            ) {
                              const keyImageIndex = study.thumbnails.findIndex(
                                item =>
                                  item.isKeyImage ||
                                  item.SeriesDescription === 'Saved KeyImages'
                              );
                              if (
                                keyImageIndex &&
                                keyImageIndex !== study.thumbnails.length - 1
                              ) {
                                const temp =
                                  study.thumbnails[study.thumbnails.length - 1];
                                study.thumbnails[study.thumbnails.length - 1] =
                                  study.thumbnails[keyImageIndex];
                                study.thumbnails[keyImageIndex] = temp;
                              }
                            }
                          }
                          if (
                            !study.thumbnails.find(thumb => thumb.isKeyImage)
                          ) {
                            loadKeyImageDisplaySet(
                              studyMetadata.find(
                                item =>
                                  item.StudyInstanceUID === StudyInstanceUID
                              )
                            );
                          }
                          return study.thumbnails.map((thumb, thumbIndex) => {
                            // TODO: Thumb has more props than we care about?
                            const {
                              active,
                              altImageText,
                              displaySetInstanceUID,
                              imageId,
                              isKeyImage,
                              derivedDisplaySetsNumber,
                              numImageFrames,
                              SeriesDescription,
                              SeriesNumber,
                              hasWarnings,
                              hasDerivedDisplaySets,
                            } = thumb;
                            const metastudy = studyMetadata.find(
                              item => item.StudyInstanceUID === StudyInstanceUID
                            );
                            const keydisplayset = metastudy.displaySets.find(
                              set => set.isKeyImage === true
                            );
                            return (
                              <div
                                key={thumb.displaySetInstanceUID}
                                className="thumbnail-container"
                                data-cy="thumbnail-list"
                              >
                                {!isKeyImage ? (
                                  <Thumbnail
                                    is2dmprActive={is2dmprActive}
                                    active={active}
                                    supportsDrag={supportsDrag}
                                    key={`${studyIndex}_${thumbIndex}`}
                                    id={`${studyIndex}_${thumbIndex}`} // Unused?
                                    // Study
                                    StudyInstanceUID={StudyInstanceUID} // used by drop
                                    // Thumb
                                    altImageText={altImageText}
                                    imageId={imageId}
                                    derivedDisplaySetsNumber={
                                      derivedDisplaySetsNumber
                                    }
                                    displaySetInstanceUID={
                                      displaySetInstanceUID
                                    } // used by drop
                                    numImageFrames={numImageFrames}
                                    SeriesDescription={SeriesDescription}
                                    SeriesNumber={SeriesNumber}
                                    hasWarnings={hasWarnings}
                                    hasDerivedDisplaySets={
                                      hasDerivedDisplaySets
                                    }
                                    // Events
                                    onClick={onThumbnailClick.bind(
                                      undefined,
                                      displaySetInstanceUID
                                    )}
                                    keyImage={false}
                                    onDoubleClick={onThumbnailDoubleClick}
                                    showProgressBar={showThumbnailProgressBar}
                                  />
                                ) : (
                                  <>
                                    {!keydisplayset ||
                                    !metastudy ||
                                    !metastudy.keyUids ? (
                                      <div className="noKeyImage">
                                        No Key Images Saved
                                      </div>
                                    ) : (
                                      <>
                                        {metastudy.keyUids &&
                                          metastudy.keyUids.length === 0 && (
                                            <div className="noKeyImage">
                                              No Key Images Saved
                                            </div>
                                          )}
                                      </>
                                    )}
                                    {metastudy.keyUids &&
                                      metastudy.keyUids.length > 0 && (
                                        <Thumbnail
                                          is2dmprActive={is2dmprActive}
                                          active={active}
                                          supportsDrag={supportsDrag}
                                          key={`${studyIndex}_${thumbIndex}`}
                                          id={`${studyIndex}_${thumbIndex}`} // Unused?
                                          // Study
                                          StudyInstanceUID={StudyInstanceUID} // used by drop
                                          // Thumb
                                          altImageText={'KeyImages'}
                                          imageId={
                                            !imageId &&
                                            metastudy.keyImages.length > 0
                                              ? 'dicomweb:' +
                                                metastudy.keyImages[0].wadouri
                                              : imageId
                                          }
                                          derivedDisplaySetsNumber={
                                            derivedDisplaySetsNumber
                                          }
                                          displaySetInstanceUID={
                                            displaySetInstanceUID
                                          } // used by drop
                                          numImageFrames={
                                            metastudy.keyUids.length
                                          }
                                          SeriesDescription={SeriesDescription}
                                          SeriesNumber={SeriesNumber}
                                          hasWarnings={hasWarnings}
                                          hasDerivedDisplaySets={
                                            hasDerivedDisplaySets
                                          }
                                          // Events
                                          onClick={onThumbnailClick.bind(
                                            undefined,
                                            displaySetInstanceUID
                                          )}
                                          keyImage={true}
                                          onDoubleClick={onThumbnailDoubleClick}
                                          showProgressBar={
                                            showThumbnailProgressBar
                                          }
                                        />
                                      )}
                                  </>
                                )}
                              </div>
                            );
                          });
                        }
                      })
                      .flat()}
                    {!studies.find(
                      item =>
                        item.StudyInstanceUID === patientStudy.StudyInstanceUID
                    ) &&
                      clickedStudy.find(
                        clicked =>
                          clicked.StudyInstanceUID ===
                          patientStudy.StudyInstanceUID
                      ) && <div className="loader"></div>}
                  </div>
                </div>
              );
            }
          })}
      </div>
    </div>
  );
}

const noop = () => {};

StudyBrowser.propTypes = {
  is2dmprActive: PropTypes.bool,
  studies: PropTypes.arrayOf(
    PropTypes.shape({
      StudyInstanceUID: PropTypes.string.isRequired,
      thumbnails: PropTypes.arrayOf(
        PropTypes.shape({
          altImageText: PropTypes.string,
          displaySetInstanceUID: PropTypes.string.isRequired,
          imageId: PropTypes.string,
          derivedDisplaySetsNumber: PropTypes.number,
          numImageFrames: PropTypes.number,
          SeriesDescription: PropTypes.string,
          SeriesNumber: PropTypes.number,
          stackPercentComplete: PropTypes.number,
        })
      ),
    })
  ).isRequired,
  supportsDrag: PropTypes.bool,
  onThumbnailClick: PropTypes.func,
  onThumbnailDoubleClick: PropTypes.func,
  showThumbnailProgressBar: PropTypes.bool,
  patientStudies: PropTypes.array,
  primaryStudy: PropTypes.object,
  addStudyInstanceUids: PropTypes.func,
  studyMetadata: PropTypes.array,
  activeViewport: PropTypes.object,
  loadKeyImageDisplaySet: PropTypes.func,
};

StudyBrowser.defaultProps = {
  studies: [],
  supportsDrag: true,
  onThumbnailClick: noop,
  onThumbnailDoubleClick: noop,
  showThumbnailProgressBar: true,
};

export { StudyBrowser };
