/* eslint-disable prettier/prettier */
import './StudyList.styl';

import React, { useEffect, useRef, useState } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { StudyListLoadingText } from './StudyListLoadingText.js';
import { useTranslation } from 'react-i18next';
import { Icon } from '../../elements/Icon';
import TableSearchFilter from './TableSearchFilter';
import RightClickMenu from './RightClickMenu';
import ModalContent from './ModalContent';
import StudyInfoContent from './StudyInfoContent';
import AttachDocument from './AttachDocument';
import AssignToUserContent from './AssignToUserContent';
import MenuItems from '../../../../viewer/src/components/DropedownComponent/DropedownComponentMenuItems';
import Snackbar from './Snackbar';
import CommentsContainer from './CommentsContainer'
// const getContentFromUseMediaValue = (
//   displaySize,
//   contentArrayMap,
//   defaultContent
// ) => {
//   const content =
//     displaySize in contentArrayMap
//       ? contentArrayMap[displaySize]
//       : defaultContent;

//   return content;
// };

/**
 *
 *
 * @param {*} props
 * @returns
 */
function StudyList(props) {
  const {
    isSideBarExpanded,
    isLoading,
    hasError,
    tableMeta,
    studies,
    sort,
    onSort: handleSort,
    filterValues,
    onFilterChange: handleFilterChange,
    onSelectItem: handleSelectItem,
    studyListDateFilterNumDays,
    displaySize,
    updateTableMeta,
    updateTableMetaOrder,
    checkedRowData,
    updateCheckedRowData,
    ChangeTableMetaColumnsize,
    inputstyle,
    checkUncheckAll,
    setCheckUncheckAll,
    centerArray,
    userInfo,
    SetSelectedPriority,
    selectedPriority,
    PriorityOptions,
    reportStatusArry,
    reportStatuss,
    SetReportStatus,
    tempCenterArray,
    center,
    SetCenter,
    modalitiesArray,
    modality,
    setModality,
    tempModalitie,
    user,
  } = props;
  const { t, ready: translationsAreReady } = useTranslation('StudyList');
  const [clickedColumn, setClickedColumn] = useState(false);
  const [pointsColumn, setPointsColumn] = useState({
    x: 0,
    y: 0,
  });
  const [column, setColumn] = useState({});
  const [clicked, setClicked] = useState(false);
  const [points, setPoints] = useState({
    x: 0,
    y: 0,
  });

  // for drag and dropping the columns
  // which column is dragging
  const [dragOver, setDragOver] = useState('');

  // dropping column
  const [colIdx, setcolIdx] = useState({});

  // dragging setting column
  const handleDragStart = (field, e) => {
    setcolIdx(field);
    setClickedColumn(false);
  };

  // drag over another column and set the value of the column below the dragging column
  const handleDragOver = e => e.preventDefault();
  const handleDragEnter = (field, e) => {
    e.preventDefault();
    const { fieldName } = field;
    setDragOver(fieldName);
  };

  // dropping the dragged column to the right of below column
  const handleOnDrop = e => {
    e.preventDefault();
    const field = tableMeta.find(item => item.fieldName === dragOver);
    const tempArray = [colIdx];
    updateTableMetaOrder(field, tempArray, 'show');
    setDragOver('');
  };

  const [ref, setRef] = useState(useRef(null));
  // const baseReportURL = window.config.servers.dicomWeb[0].reportRoot;

  useEffect(() => {
    function handleClickOutside(event) {
      if (ref.current && !ref.current.contains(event.target)) {
        handleClick(false);
      }
    }
    document.addEventListener('click', handleClickOutside);
    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, [filterValues, ref]);

  const handleClick = clickedValue => {
    setClickedColumn(clickedValue);
  };
  const handleRef = refValue => {
    setRef(refValue);
  };

  const handleContextMenuValues = (clicked, points, column) => {
    setClickedColumn(clicked);
    if (clicked) {
      setClicked(false);
    }
    setPointsColumn(points);
    setColumn(column);
  };
  // const totalSize = tableMeta
  //   .map(field => field.size)
  //   .reduce((prev, next) => prev + next);
  const [row, setRow] = useState('');
  const updateRow = data => {
    setRow(data);
  };
  const updateClickedColumn = data => {
    setClickedColumn(data);
  };
  const updateClicked = data => {
    setClicked(data);
  };
  const updatePoints = data => {
    setPoints(data);
  };

  // function check and uncheck all the checkboxes when the select all checkbox is checked or unchecked
  const checkUncheck = () => {
    var getAllCheckboxes = document.getElementsByName('checkRow');
    for (let i = 0; i < getAllCheckboxes.length; i++) {
      getAllCheckboxes[i].checked = !checkUncheckAll;
    }
    if (!checkUncheckAll) {
      updateCheckedRowData(studies);
    } else {
      updateCheckedRowData([]);
    }
    setCheckUncheckAll(!checkUncheckAll);
  };

  const [userConfirmation, setUserConfirmation] = useState(false);
  const [studyData, setStudyData] = useState({});
  const updateConfirmationOpen = item => {
    setUserConfirmation(item);
    setStudyData({});
  };

  const UserConfirmation = () => {
    var assignedUsers = '';
    if (studyData.assignedUserArray) {
      for (
        let index = 0;
        index < studyData.assignedUserArray.length;
        index++
      ) {
        assignedUsers += studyData.assignedUserArray[index].userName + ', ';
      }
      assignedUsers = assignedUsers.substring(0, assignedUsers.length - 2);
    }
    return (
      <>
        <div className="userConfirm">
          <div className="userConfirm-body">
            <p>{'This study is assigned to other user(s)'}</p>
            <h3>{assignedUsers}</h3>
            <p>{'Do you want to proceed ?'}</p>
          </div>
          <div className="userConfirm-footer">
            <button
              onClick={() => {
                setUserConfirmation(false);
                setStudyData({});
              }}
            >
              Cancel
            </button>
            <button
              onClick={() => {
                handleSelectItem(
                  studyData.StudyInstanceUID,
                  studyData.PatientID
                );
                setUserConfirmation(false);
                setStudyData({});
              }}
            >
              Confirm
            </button>
          </div>
        </div>
      </>
    );
  };

  return translationsAreReady ? (
    <table className="table table--striped table--hoverable">
      {/* <colgroup>
        {tableMeta.map((field, i) => {
          if (field.show) {
            const size = field.size;
            // const percentWidth = (size / totalSize) * 100.0;
            return <col key={i} style={{ width: `${size}px` }} />;
          }
        })}
      </colgroup> */}
      <thead className="table-head">
        <tr className="filters">
          <TableSearchFilter
            inputstyle={inputstyle}
            ChangeTableMetaColumnsize={ChangeTableMetaColumnsize}
            meta={tableMeta}
            studies={studies}
            isLoading={isLoading}
            values={filterValues}
            onSort={handleSort}
            onValueChange={handleFilterChange}
            sortFieldName={sort.fieldName}
            sortDirection={sort.direction}
            studyListDateFilterNumDays={studyListDateFilterNumDays}
            handleContextMenuValues={handleContextMenuValues}
            checkUncheck={checkUncheck}
            checkUncheckAll={checkUncheckAll}
            checkedRowData={checkedRowData}
            centerArray={centerArray}
            handleDragStart={handleDragStart}
            handleDragOver={handleDragOver}
            handleOnDrop={handleOnDrop}
            handleDragEnter={handleDragEnter}
            dragOver={dragOver}
            selectedPriority={selectedPriority}
            SetSelectedPriority={SetSelectedPriority}
            PriorityOptions={PriorityOptions}
            reportStatusArry={reportStatusArry}
            reportStatuss={reportStatuss}
            SetReportStatus={SetReportStatus}
            tempCenterArray={tempCenterArray}
            SetCenter={SetCenter}
            center={center}
            modality={modality}
            setModality={setModality}
            handleClick={handleClick}
            modalitiesArray={modalitiesArray}
            tempModalitie={tempModalitie}
          />
          <td><Snackbar /></td>
        </tr>
        {clickedColumn && (
          <RightClickMenu
            top={pointsColumn.y}
            left={pointsColumn.x}
            isSideBarExpanded={isSideBarExpanded}
            field={column}
            updateTableMeta={updateTableMeta}
            updateTableMetaOrder={updateTableMetaOrder}
            handleClick={handleClick}
            handleRef={handleRef}
            tableMeta={tableMeta}
          />
        )}
      </thead>
      <tbody className="table-body" data-cy="study-list-results">
        {/* I'm not in love with this approach, but it's the quickest way for now
         *
         * - Display different content based on loading, empty, results state
         *
         * This is not ideal because it create a jump in focus. For loading especially,
         * We should keep our current results visible while we load the new ones.
         */}
        {/* LOADING */}
        {isLoading && (
          <tr className="no-hover">
            <td colSpan={tableMeta.length}>
              <StudyListLoadingText />
            </td>
          </tr>
        )}
        {!isLoading && hasError && (
          <tr className="no-hover">
            <td colSpan={tableMeta.length}>
              <div className="notFound">
                {t('There was an error fetching studies')}
              </div>
            </td>
          </tr>
        )}
        {/* EMPTY */}
        {!isLoading && !studies.length && (
          <tr className="no-hover">
            <td colSpan={tableMeta.length}>
              <div className="notFound">{t('No matching results')}</div>
            </td>
          </tr>
        )}
        {!isLoading &&
          studies.map((study, index) => (
            <TableRow
              checkedRowData={checkedRowData}
              updateCheckedRowData={updateCheckedRowData}
              key={`${study.StudyInstanceUID}-${index}`}
              rowKey={`${study.StudyInstanceUID}-${index}`}
              onClick={async (StudyInstanceUID, PatientID) => {
                var userarray = [];
                var headerData = new Headers();
                var idtoken = JSON.parse(
                  sessionStorage.getItem(
                    'oidc.user:' +
                      window.config.oidc[0].authority +
                      ':' +
                      window.config.oidc[0].client_id
                  )
                );
                var bearerToken = 'bearer ' + idtoken.id_token;
                headerData.append('Content-Type', 'application/json');
                headerData.append('Access-Control-Allow-Origin', '*');
                headerData.append('Authorization', bearerToken);

                var request = {
                  method: 'POST',
                  headers: headerData,
                  body: '',
                  redirect: 'follow',
                };

                await fetch(
                  `${window.config.servers.dicomWeb[0].apiRoot}/api/assignuser/fetch/${StudyInstanceUID}`,
                  request
                )
                  .then(response => response.json())
                  .then(result => (userarray = result))
                  .catch(error => console.error('error', error));
                const assignedUserArray = userarray.filter(
                  user => user.isAssigned === 'Y'
                );
                if (
                  assignedUserArray.length === 0 ||
                  assignedUserArray.find(
                    userData =>
                      userData.userId === sessionStorage.getItem('loggedinuser')
                  )
                ) {
                  handleSelectItem(StudyInstanceUID, PatientID);
                } else {
                  setUserConfirmation(true);
                  setStudyData({
                    StudyInstanceUID: StudyInstanceUID,
                    PatientID: PatientID,
                    assignedUserArray: assignedUserArray,
                  });
                }
              }}
              study={study}
              displaySize={displaySize}
              tableMeta={tableMeta}
              updateRow={updateRow}
              row={row}
              clickedColumn={clickedColumn}
              updateClickedColumn={updateClickedColumn}
              clicked={clicked}
              updateClicked={updateClicked}
              points={points}
              updatePoints={updatePoints}
              setCheckUncheckAll={setCheckUncheckAll}
              dragOver={dragOver}
              centerArray={centerArray}
              userInfo={userInfo}
              handleFilterChange={handleFilterChange}
              user={user}
            />
          ))}
        <ModalContent
          style={{
            overlay: {
              position: 'fixed',
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              backgroundColor: 'rgba(25,41,40,0.8)',
            },
            content: {
              position: 'relative',
              display: 'flex',
              justifyContent: 'center',
              alignContent: 'center',
              flexDirection: 'column',
              top: '25%',
              left: window.innerWidth > 820 ? '33%' : '20%',
              width: window.innerWidth > 820 ? '600px' : '65%',
              height: window.innerWidth > 820 ? '350px' : '40%',
              border: '1px solid rgba(255, 255, 255, 0.759)',
              color: '#fff',
              overflow: window.innerWidth > 450 ? 'auto' : 'hidden',
              WebkitOverflowScrolling: 'touch',
              borderRadius: '6px',
              outline: 'none',
              padding: '10px',
              backgroundColor: '#12221f',
            },
          }}
          isOpen={userConfirmation}
          updateisOpen={updateConfirmationOpen}
          content={<UserConfirmation />}
          title="Confirm User"
        />
      </tbody>
    </table>
  ) : null;
}

StudyList.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  hasError: PropTypes.bool.isRequired,
  studies: PropTypes.array.isRequired,
  onSelectItem: PropTypes.func.isRequired,
  ChangeTableMetaColumnsize: PropTypes.func,
  inputstyle: PropTypes.string,
  centerArray: PropTypes.array,
  // ~~ SORT
  sort: PropTypes.shape({
    fieldName: PropTypes.string,
    direction: PropTypes.oneOf(['desc', 'asc', null]),
  }).isRequired,
  onSort: PropTypes.func.isRequired,
  // ~~ FILTERS
  filterValues: PropTypes.shape({
    PatientName: PropTypes.string.isRequired,
    PatientID: PropTypes.string.isRequired,
    AccessionNumber: PropTypes.string.isRequired,
    StudyDate: PropTypes.string.isRequired,
    modalities: PropTypes.string.isRequired,
    StudyDescription: PropTypes.string.isRequired,
    patientNameOrId: PropTypes.string.isRequired,
    accessionOrModalityOrDescription: PropTypes.string.isRequired,
    allFields: PropTypes.string.isRequired,
    studyDateTo: PropTypes.any,
    studyDateFrom: PropTypes.any,
    referringPhysicianName: PropTypes.string.isRequired,
  }).isRequired,
  onFilterChange: PropTypes.func.isRequired,
  studyListDateFilterNumDays: PropTypes.number,
  displaySize: PropTypes.string,
  tableMeta: PropTypes.array,
  updateTableMeta: PropTypes.func,
  updateTableMetaOrder: PropTypes.func,
  clickedColumn: PropTypes.bool,
  updateClickedColumn: PropTypes.func,
  clicked: PropTypes.bool,
  updateClicked: PropTypes.func,
  points: PropTypes.object,
  updatePoints: PropTypes.func,
  checkedRowData: PropTypes.array,
  updateCheckedRowData: PropTypes.func,
  checkUncheckAll: PropTypes.bool,
  setCheckUncheckAll: PropTypes.func,
  isSideBarExpanded: PropTypes.bool,
  userInfo: PropTypes.object,
  selectedPriority: PropTypes.array,
  SetSelectedPriority: PropTypes.func,
  PriorityOptions: PropTypes.array,
  reportStatuss: PropTypes.array,
  SetReportStatus: PropTypes.func,
  reportStatusArry: PropTypes.array,
  tempCenterArray: PropTypes.array,
  center: PropTypes.array,
  modality: PropTypes.array,
  setModality: PropTypes.func,
  SetCenter: PropTypes.func,
  modalitiesArray: PropTypes.array,
  tempModalitie: PropTypes.array,
  user: PropTypes.object,
};

StudyList.defaultProps = {};
function TableRow(props) {
  const {
    study,
    rowKey: tableRowKey,
    // isHighlighted,
    onClick: handleClick,
    tableMeta,
    updateRow,
    row,
    clickedColumn,
    updateClickedColumn,
    clicked,
    updateClicked,
    points,
    updatePoints,
    checkedRowData,
    updateCheckedRowData,
    setCheckUncheckAll,
    dragOver,
    centerArray,
    userInfo,
    handleFilterChange,
    user,
    // displaySize,
  } = props;

  const { t } = useTranslation('StudyList');
  const [studyinfoOpen, setStudyinfoOpen] = useState(false);
  const [assigntoOpen, setAssigntoOpen] = useState(false);
  const [isCommentsOpen, setIsCommentsOpen] = useState(false);

  const [toShowOrNotToViewStudy, setToShowOrNotToViewStudy] = useState('N');
  const [toShowOrNotTheReport, setToShowOrNotTheReport] = useState('N');
  const [toShowOrNotToAssignToUser, setToShowOrNotToAssignToUser] = useState(
    'N'
  );
  const [toShowOrNotToSetPriority, setToShowOrNotToSetPriority] = useState('N');
  // var viewStudy = useRef('');
  // var viewReport = useRef('');
  // var assignStudy = useRef('');
  // var setPriority = useRef('');
  var center = centerArray.find(item => item.centerName === study.ffincenterid);

  useEffect(() => {
    async function fetchaccess() {
      if (
        (await userInfo.HasCenterPrivilege('C_VIEW_STUDY', center.centerId)) ||
        ((await userInfo.HasSystemPrivilege('SC_VIEW_STUDY')) &&
          (await userInfo.HasAllCentersAssigned))
      )
        setToShowOrNotToViewStudy('Y');
      if (
        (await userInfo.HasCenterPrivilege(
          'C_ACCESS_REPORT',
          center.centerId
        )) ||
        ((await userInfo.HasSystemPrivilege('SC_ACCESS_REPORT')) &&
          (await userInfo.HasAllCentersAssigned))
      )
        setToShowOrNotTheReport('Y');
      if (
        (await userInfo.HasCenterPrivilege(
          'C_ASSIGN_STUDY',
          center.centerId
        )) ||
        ((await userInfo.HasSystemPrivilege('SC_ASSIGN_STUDY')) &&
          (await userInfo.HasAllCentersAssigned))
      )
        setToShowOrNotToAssignToUser('Y');
      if (
        (await userInfo.HasCenterPrivilege(
          'C_SET_PRIORITY',
          center.centerId
        )) ||
        ((await userInfo.HasSystemPrivilege('SC_SET_PRIORITY')) &&
          (await userInfo.HasAllCentersAssigned))
      )
        setToShowOrNotToSetPriority('Y');
      // priority showing on the context menu with more than one studies selected / when multiple checked.
      if (checkedRowData.length > 1) {
        for (let index = 0; index < checkedRowData.length; index++) {
          var c = centerArray.find(
            item => item.centerName === checkedRowData[index].ffincenterid
          );
          if (
            !(
              (await userInfo.HasCenterPrivilege(
                'C_SET_PRIORITY',
                c.centerId
              )) ||
              ((await userInfo.HasSystemPrivilege('SC_SET_PRIORITY')) &&
                (await userInfo.HasAllCentersAssigned))
            )
          ) {
            setToShowOrNotToSetPriority('N');
            break;
          }
        }
      }
    }
    fetchaccess();
  }, [userInfo, study, center.centerId, checkedRowData, centerArray]);

  const [attachDocOpen, setAttachDocOpen] = useState(false);

  const [style, setStyle] = useState('unCheckedRow');
  const new_StudyInstanceUID = study.StudyInstanceUID.replace(/\./g, '');

  useEffect(() => {
    if (document.querySelector(`#checkbox-${new_StudyInstanceUID}:checked`)) {
      setStyle('selectedRow');
      // updateCheckedRowData([...checkedRowData, study]);
    } else {
      setStyle('unCheckedRow');
      // const newArray = checkedRowData.filter(item => {
      //   return item.StudyInstanceUID !== study.StudyInstanceUID;
      // });
      // updateCheckedRowData(newArray);
    }
    const handleClick = () => updateClicked(false);
    window.addEventListener('click', handleClick);
    return () => {
      window.removeEventListener('click', handleClick);
    };
  }, [new_StudyInstanceUID, updateClicked]);

  let reportStatusSetting = study.ffinreportstatus;
  if (study.ffinreportstatus === '0') {
    reportStatusSetting = 'No Report';
  } else if (study.ffinreportstatus === '1') {
    reportStatusSetting = 'Drafted';
  } else if (study.ffinreportstatus === '2') {
    reportStatusSetting = 'Preliminary';
  } else if (study.ffinreportstatus === '3') {
    reportStatusSetting = 'Finalized';
  }

  let prioritySetting = '';
  if (study.ffinpriority === '1') {
    prioritySetting = 'High';
  }
  if (study.ffinpriority === '2') {
    prioritySetting = 'Medium';
  }
  if (study.ffinpriority === '3') {
    prioritySetting = 'Low';
  }

  const dataArray = [];
  []
    .concat(tableMeta)
    .sort((a, b) => (a.order > b.order ? 1 : -1))
    .map((item, i) => {
      if (item.fieldName === 'ffinreportstatus') {
        dataArray.push({
          fieldName: item.fieldName,
          action: reportStatusSetting,
        });
      } else if (item.fieldName === 'ffinpriority') {
        dataArray.push({
          fieldName: item.fieldName,
          action: prioritySetting,
        });
        // } else if (item.fieldName === 'ffincenterid') {
        //   dataArray.push({
        //     fieldName: item.fieldName,
        //     action: centerDetailOfStudy.centerName,
        //   });
      } else if (item.fieldName !== 'Actions') {
        let actionValue = '';
        Object.entries(study).map(([key, val]) => {
          if (key === item.fieldName) {
            actionValue = val;
          }
        });
        dataArray.push({
          fieldName: item.fieldName,
          action: actionValue,
        });
      }
    });

  const prioritySubmenu = [];

  // right-click menu items
  const contextMenuItems = [];
  contextMenuItems.push({
    className: 'itemWithBorder',
    title: 'Set Priority',
    disabled: toShowOrNotToSetPriority === 'N' ? true : false,
    submenu: prioritySubmenu,
  });
  // the items which are displayed only for single study or multiple studies
  if (checkedRowData.length <= 1) {
    contextMenuItems.unshift({
      className: 'itemWithBorder',
      title: 'Assign To User',
      disabled: toShowOrNotToAssignToUser === 'N' ? true : false,
      onclick: () => {
        setAssigntoOpen(true);
      },
    });

    contextMenuItems.unshift({
      className: 'itemWithBorder',
      title: 'View Report',
      disabled: toShowOrNotTheReport === 'N' ? true : false,
      onclick: () => {
        const reportURL = `${
          window.config.servers.dicomWeb[0].reportRoot
        }/view/Editor/RichEditor?Mode=ReportEdit&StudyId=${
          study.StudyInstanceUID
        }&ReportId=id1&currentuser=${sessionStorage.getItem('loggedinuser')}`;
        window.open(reportURL, '_blank');
      },
    });

    if (study.ffinpriority !== '1') {
      prioritySubmenu.push({
        className: 'itemWithBorder',
        title: 'High',
        onclick: () => ToSetPriority(1, study.StudyInstanceUID),
      });
    }
    if (study.ffinpriority !== '2') {
      prioritySubmenu.push({
        className: 'itemWithBorder',
        title: 'Medium',
        onclick: () => ToSetPriority(2, study.StudyInstanceUID),
      });
    }
    if (study.ffinpriority !== '3') {
      prioritySubmenu.push({
        className: 'itemWithBorder',
        title: 'Low',
        onclick: () => ToSetPriority(3, study.StudyInstanceUID),
      });
    }
    contextMenuItems.unshift({
      className: 'itemWithBorder',
      title: 'View Study',
      disabled: toShowOrNotToViewStudy === 'N' ? true : false,
      onclick: () => {
        handleClick(study.StudyInstanceUID, study.PatientID);
      },
    });
    contextMenuItems.push({
      className: 'itemWithBorder',
      title: 'Attach Document',
      onclick: () => {
        setAttachDocOpen(true);
      },
    });
    contextMenuItems.push({
      className: 'itemWithoutBorder',
      title: 'Study Info',
      onclick: () => {
        setStudyinfoOpen(true);
      },
    });
  } else {
    prioritySubmenu.push({
      className: 'itemWithBorder',
      title: 'High',
      onclick: () => {
        checkedRowData.map(item => {
          ToSetPriority(1, item.StudyInstanceUID);
        });
      },
    });
    prioritySubmenu.push({
      className: 'itemWithBorder',
      title: 'Medium',
      onclick: () => {
        checkedRowData.map(item => {
          ToSetPriority(2, item.StudyInstanceUID);
        });
      },
    });
    prioritySubmenu.push({
      className: 'itemWithBorder',
      title: 'Low',
      onclick: () => {
        checkedRowData.map(item => {
          ToSetPriority(3, item.StudyInstanceUID);
        });
      },
    });
  }

  const updateStudyinfoOpen = item => {
    setStudyinfoOpen(item);
  };
  const updateAssigntoOpen = item => {
    setAssigntoOpen(item);
    };

  const updateAttachDocOpen = item => {
    setAttachDocOpen(item);
  };
  const updateCommentContainer = item => {
    setIsCommentsOpen(item);
  };

  const toShowTheViewIcon = () => {
    return toShowOrNotToViewStudy === 'N' ? true : false;
  };
  const toShowTheReportIcon = () => {
    return toShowOrNotTheReport === 'N' ? true : false;
  };
  const scrollLeft = document.querySelector('#study-table').scrollLeft;
  const scrollTop = document.querySelector('#study-table').scrollTop;
  let dropdownSubmenuClassName = 'dropdown-submenu-left';

  let leftValue = points.x;
  let topValue = points.y;

  if (window.innerWidth - points.x < 155) {
    leftValue = points.x - 150;
  }
  if (window.innerWidth - points.x < 315) {
    dropdownSubmenuClassName = 'dropdown-submenu-right';
  }
  if (window.innerHeight - points.y < 300 && checkedRowData.length <= 1) {
    topValue = points.y - 235;
  }

  if (window.innerHeight - points.y < 220 && checkedRowData.length > 1) {
    topValue = points.y - 38;
    dropdownSubmenuClassName = dropdownSubmenuClassName + ' bottomsubmenu';
  }
  const contextMenuItemsStyle = {
    top: `${topValue - 75 + scrollTop}px`,
    left: `${leftValue + scrollLeft}px`,
    display: window.innerWidth < 820 ? 'none' : 'block',
  };

  const AttachDoc = () => {
    return <AttachDocument stdyUID={study.StudyInstanceUID} />;
  };
  const StudyInfo = () => {
    return <StudyInfoContent study={study} />;
  };
  const Comments = () =>{
    return <CommentsContainer study={study} stdyUID={study.StudyInstanceUID} user={user} />;
  }
  const ToSetPriority = (priority, studyInstanceUID) => {
    const url = `${window.config.servers.dicomWeb[0].apiRoot}/api/priority/`;
    var idtoken = JSON.parse(
      sessionStorage.getItem(
        'oidc.user:' +
          window.config.oidc[0].authority +
          ':' +
          window.config.oidc[0].client_id
      )
    );
    var bearerToken = 'bearer ' + idtoken.id_token;
    fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Orgin': '*',
        Authorization: bearerToken,
      },
      body: JSON.stringify({
        priority: priority,
        studyinstanceuid: studyInstanceUID,
      }),
    });
    handleFilterChange('StudyTime', '');
  };
  const AssignToUser = () => {
    let studyData = [];
    if (checkedRowData.length === 0) {
      studyData.push(study);
    } else {
      studyData = checkedRowData;
    }
    return (
      <AssignToUserContent
        updateAssigntoOpen={updateAssigntoOpen}
        studyData={studyData}
      />
    );
  };

  const rowTemplate = (
    <>
      <tr
        key={tableRowKey}
        onClick={() => updateRow(study.StudyInstanceUID)}
        className={
          // Rightclick selection with context menu
          (clicked &&
            row === study.StudyInstanceUID &&
            (checkedRowData.length === 0 ||
              checkedRowData.find(
                item => item.StudyInstanceUID === study.StudyInstanceUID
              ))) ||
          // Left click selection
          (row === study.StudyInstanceUID &&
            (checkedRowData.length === 0 ||
              checkedRowData.find(
                item => item.StudyInstanceUID === study.StudyInstanceUID
              )))
            ? 'selectedRow'
            : style
        }
        onContextMenu={e => {
          e.preventDefault();
          updateClicked(true);
          if (clickedColumn) {
            updateClickedColumn(false);
          }
          updatePoints({ x: e.pageX, y: e.pageY });
          updateRow(study.StudyInstanceUID);
        }}
      >
        <td
          // dragOver={'Actions' === dragOver}
          style={
            'Actions' === dragOver
              ? { borderRight: '3px solid rgb(95, 93, 93)' }
              : {}
          }
        >
          <input
            type="checkbox"
            name="checkRow"
            id={`checkbox-${new_StudyInstanceUID}`}
            className="rowCheckbox"
            onChange={() => {
              if (
                document.querySelector(
                  `#checkbox-${new_StudyInstanceUID}:checked`
                )
              ) {
                updateCheckedRowData([...checkedRowData, study]);
              } else {
                const newArray = checkedRowData.filter(item => {
                  return item.StudyInstanceUID !== study.StudyInstanceUID;
                });
                updateCheckedRowData(newArray);
                setCheckUncheckAll(false);
              }
            }}
          />
          <button
            className="action-button"
            id="Reportaction-button"
            title="Report"
            style={{display: window.innerWidth < 825 ? 'none' : '' }}
            disabled={toShowTheReportIcon()}
            onClick={() => {
              const reportURL = `${
                window.config.servers.dicomWeb[0].reportRoot
              }/view/Editor/RichEditor?Mode=ReportEdit&StudyId=${
                study.StudyInstanceUID
              }&ReportId=id1&currentuser=${sessionStorage.getItem(
                'loggedinuser'
              )}`;
              window.open(reportURL, '_blank');
            }}
          >
            <Icon name="edit-document" />
          </button>
          {/* </Route> */}
          <button
            className="action-button"
            disabled={toShowTheViewIcon()}
            onClick={() => handleClick(study.StudyInstanceUID, study.PatientID)}
            title="Viewer"
          >
            <Icon name="visibility" />
          </button>
          <button
            className="action-button"
            onClick ={()=>setAttachDocOpen(true)}   
            title="Viewer"
            style={{display: window.innerWidth < 825 ? 'none' : '', color: study.ffinNoOfDocuments === "0" ? '#fff' : 'rgb(122, 194, 124)'}}
          >
            <Icon name="attachDocument" />
          </button>
          <button
            className="action-button"
            onClick ={()=>setIsCommentsOpen(true)}   
            title="Viewer"
            style={{display: window.innerWidth < 825 ? 'none' : '', color: study.ffinNoOfClinicalNotes === "0" ? '#fff' : 'rgb(122, 194, 124)'}}
          >
            <Icon name="clinicalNotes" />
          </button>
        </td>
        {dataArray.map((item, itemIndex) => {
          const tableData = tableMeta.find(
            data => data.fieldName === item.fieldName && data.show
          );
          if (tableData) {
            return (
              <>
                <td
                  key={`${item.fieldName}-${itemIndex}`}
                  onDoubleClick={() => {
                    if (toShowOrNotToViewStudy !== 'N') {
                      handleClick(study.StudyInstanceUID, study.PatientID);
                    }
                  }}
                  className={classNames({ 'empty-value': !item.action })}
                  title={item.action}

                  // dragOver={item.fieldName === dragOver}
                  style={{
                    // color: item.action === 'Finalized'? 'rgb(143 245 145)' : 'white',
                    // fontSize: item.action === 'Finalized'? "14px" : '12px',
                    // fontWeight: item.action === 'Finalized'? "1000" : 'normal',
                    backgroundColor: item.action === 'Finalized'? '#558256' : 'transparent',
                    borderRight: item.fieldName === dragOver?'3px solid rgb(95, 93, 93)' : 'none'                  
                  }}
                >
                  {item.action || `${t(' ')}`}
                </td>
                {/* Column Resizer */}
                <td className="columnResizer tdcolumn" key={`-${itemIndex}`} />
              </>
            );
          }
        })}
      </tr>
      {clicked && // rightclick
      contextMenuItems.length !== 0 && // context menu items array empty
      row === study.StudyInstanceUID && // selected row
        (checkedRowData.length === 0 || // array length 0
          checkedRowData.find(
            item => item.StudyInstanceUID === study.StudyInstanceUID // array contains the selected row
          )) && (
          <div className="contextMenu" style={contextMenuItemsStyle}>
            <ul className="dropdown show">
              {contextMenuItems.map(item => {
                const depthLevel = 1;
                return (
                  <>
                    <MenuItems
                      items={item}
                      depthLevel={depthLevel}
                      dropdownSubmenuClassName={dropdownSubmenuClassName}
                    />
                  </>
                );
              })}
            </ul>
          </div>
        )}
      <ModalContent
        isOpen={studyinfoOpen}
        updateisOpen={updateStudyinfoOpen}
        content={<StudyInfo />}
        title="Study Details"
      />
      <ModalContent
        isOpen={assigntoOpen}
        updateisOpen={updateAssigntoOpen}
        content={<AssignToUser />}
        title="Select User"
      />

      <ModalContent
        isOpen={attachDocOpen}
        updateisOpen={updateAttachDocOpen}
        content={<AttachDoc />}
        title="Attach Document"
      />
      <ModalContent
        isOpen={isCommentsOpen}
        updateisOpen={updateCommentContainer}
        content={<Comments />}
        title="Comments"
      />
    </>
  );
  return rowTemplate;
}

TableRow.propTypes = {
  isHighlighted: PropTypes.bool,
  study: PropTypes.any,
  displaySize: PropTypes.string,
};

TableRow.defaultProps = {
  isHighlighted: false,
};

export { StudyList };
