import React from 'react';
import Modal from 'react-modal';
import './ErrorPopupModal.css';
Modal.setAppElement(document.getElementById('root'));

function ErrorPopupModal({ isOpen, updateisOPen, title, content, buttonText }) {
  return (
    <Modal
      isOpen={isOpen}
      style={{
        overlay: {
          position: 'fixed',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          backgroundColor: 'rgba(25,41,40,0.8)',
        },
        content: {
          position: 'absolute',
          display: 'flex',
          flexDirection: 'column',
          top: '20%',
          left: '25%',
          right: '25%',
          bottom: '20%',
          border: '1px solid rgba(255, 255, 255, 0.759)',
          color: '#fff',
          overflow: 'auto',
          WebkitOverflowScrolling: 'touch',
          borderRadius: '6px',
          outline: 'none',
          padding: '10px',
          backgroundColor: '#12221f',
        },
      }}
    >
      <div className="container">
        <div className="titleContent">
          <h2>{title}</h2>
        </div>
        <div className="content">{content}</div>
        <button className="modalbutton" onClick={() => updateisOPen(false)}>
          {buttonText}
        </button>
      </div>
    </Modal>
  );
}
export default ErrorPopupModal;
