import React from 'react';
import WhiteLabelingContext from '../../context/WhiteLabelingContext';
import UserManagerContext from '../../context/UserManagerContext';
import AppContext from '../../context/AppContext';
import ConnectedHeader from '../../connectedComponents/ConnectedHeader';

function ReportHeader() {
  return (
    <div>
      <WhiteLabelingContext.Consumer>
        {whiteLabeling => (
          <UserManagerContext.Consumer>
            {userManager => (
              <AppContext.Consumer>
                {appContext => (
                  <ConnectedHeader
                    linkText={
                      appContext.appConfig.showStudyList
                        ? 'Study List'
                        : undefined
                    }
                    linkPath={
                      appContext.appConfig.showStudyList ? '/' : undefined
                    }
                    userManager={userManager}
                  >
                    {whiteLabeling &&
                      whiteLabeling.createLogoComponentFn &&
                      whiteLabeling.createLogoComponentFn(React)}
                  </ConnectedHeader>
                )}
              </AppContext.Consumer>
            )}
          </UserManagerContext.Consumer>
        )}
      </WhiteLabelingContext.Consumer>
    </div>
  );
}
export default ReportHeader;
