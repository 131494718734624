import React, { useEffect } from 'react';
import './Uploader.css';
import { useState, useRef } from 'react';
import axios from 'axios';

const Uploader = ({ studyUID, fetch }) => {
  const userId = sessionStorage.getItem('loggedinuser');
  const [file, setFile] = useState(null);
  const [fileName, setFileName] = useState('');
  const [uploadedDocDes, setUploadedDocDes] = useState('');
  const [uploadedMessage, setUploadedMessage] = useState({
    message: '',
    type: 'info',
  });
  const [uploadedFile, setUploadedFile] = useState(false);
  const inputRef = useRef('');
  const BaseURL = window.config.servers.dicomWeb[0].wadoRoot;
  const defaultUploadedFileSize = 10;
  const DesChangeHandler = e => {
    setUploadedDocDes(e.target.value);
  };

  const idtoken = JSON.parse(
    sessionStorage.getItem(
      'oidc.user:' +
        window.config.oidc[0].authority +
        ':' +
        window.config.oidc[0].client_id
    )
  );
  const bearerToken = 'bearer ' + idtoken.id_token;

  const FileChangeHandler = e => {
    const fileSize = inputRef.current.files[0].size / (1024 * 1024);
    if (fileSize > defaultUploadedFileSize) {
      setFile(null);
    } else {
      setFile(inputRef.current.files[0]);
      setFileName(inputRef.current.files[0].name);
    }
  };

  const getExtension = filename => {
    return filename.split('.').pop();
  };

  const submitHandler = event => {
    event.preventDefault();
    if (file === null) return;
    setUploadedFile(true);
    if (getExtension(file.name.toLowerCase()) !== 'pdf') {
      return;
    }
    const url = `${BaseURL}docupload/`;
    const data = new FormData();
    data.append('file', file);
    data.append('fileName', file.name);

    axios
      .post(url, data, {
        params: {
          studyinstanceuid: studyUID,
          documentdescription: uploadedDocDes,
          createdby: userId,
        },
        headers: {
          'Content-Type': 'application/pdf',
          Authorization: bearerToken,
        },
      })
      .then(response => {
        if (response.status === 200) {
          setUploadedFile(false);
          cancelHandler();
          setUploadedMessage({
            message: 'Uploaded Successfully',
            type: 'success',
          });
          fetch();
          setTimeout(() => {
            setUploadedMessage({
              message: '',
              type: '',
            });
          }, 1000);
        }
      })
      .catch(err => console.log(err));
  };

  const cancelHandler = () => {
    setFileName('');
    setUploadedDocDes('');
    setFile(null);
  };

  useEffect(() => {
    if (file && getExtension(file.name.toLowerCase()) !== 'pdf') {
      setFile(null);
      setUploadedMessage({
        message: '* Only pdf files allowed',
        type: 'warning',
      });
      setTimeout(() => {
        setUploadedMessage({
          message: '',
          type: '',
        });
      }, 5000);
    }
  }, [file]);

  return (
    <div className="main">
      <form
        className="selctFilesForm"
        onClick={() => document.querySelector('.input-field').click()}
      >
        <h5
          className="inputHead"
          style={{
            color:
              uploadedMessage.message !== ''
                ? uploadedMessage.type === 'warning'
                  ? '#ffbf00'
                  : '#00FF90'
                : '#ffffff',
          }}
        >
          {file
            ? fileName
            : uploadedMessage.message !== ''
            ? uploadedMessage.message
            : 'Select files.. ( Max size 10mb )'}
        </h5>
      </form>
      <input
        type="file"
        ref={inputRef}
        onChange={FileChangeHandler}
        accept="application/pdf"
        name=""
        id=""
        value={''}
        className="input-field"
      />
      <div className="buttons">
        <input
          className="docDesInput"
          maxLength={50}
          name="file"
          value={uploadedDocDes}
          onChange={DesChangeHandler}
          type="text"
          placeholder="Doc Description ( Max 50 Char )"
        ></input>

        <button 
          className="file-input_upload" 
          disabled={file === null || uploadedFile}
          onClick={(e) => {
            if (!uploadedFile)
              submitHandler(e);
          }}
        >
          Upload
        </button>
        <button 
          className="file-input_Cancel" 
          disabled={file === null}
          onClick={cancelHandler}
        >
          Cancel
        </button>
      </div>
    </div>
  );
};

export default Uploader;
