import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

function AssignToUserContent({ updateAssigntoOpen, studyData }) {
  const [userData, setUserData] = useState([]);
  // assigned user array
  const [assignedUserData, setAssignedUserData] = useState([]);

  useEffect(() => {
    // getting user data from the api
    const getUserData = async () => {
      var headerData = new Headers();
      var idtoken = JSON.parse(
        sessionStorage.getItem(
          'oidc.user:' +
            window.config.oidc[0].authority +
            ':' +
            window.config.oidc[0].client_id
        )
      );
      var bearerToken = 'bearer ' + idtoken.id_token;
      headerData.append('Content-Type', 'application/json');
      headerData.append('Access-Control-Allow-Origin', '*');
      headerData.append('Authorization', bearerToken);

      var request = {
        method: 'POST',
        headers: headerData,
        body: '',
        redirect: 'follow',
      };

      await fetch(
        `${window.config.servers.dicomWeb[0].apiRoot}/api/assignuser/fetch/${studyData[0].StudyInstanceUID}`,
        request
      )
        .then(response => response.json())
        .then(result => setUserData(result))
        .catch(error => console.error('error', error));
    };
    getUserData();
  }, []);

  useEffect(() => {
    let temp = [];
    userData.map(item => {
      if (item.isAssigned === 'Y') {
        temp.push(item.userId);
      }
    });
    setAssignedUserData(temp);
  }, [userData]);

  const postUserData = async postbody => {
    var headerData = new Headers();
    var idtoken = JSON.parse(
      sessionStorage.getItem(
        'oidc.user:' +
          window.config.oidc[0].authority +
          ':' +
          window.config.oidc[0].client_id
      )
    );
    var bearerToken = 'bearer ' + idtoken.id_token;
    headerData.append('Content-Type', 'application/json');
    headerData.append('Access-Control-Allow-Origin', '*');
    headerData.append('Authorization', bearerToken);

    var postBody = JSON.stringify(postbody);

    var request = {
      method: 'POST',
      headers: headerData,
      body: postBody,
      redirect: 'follow',
    };

    await fetch(
      `${window.config.servers.dicomWeb[0].apiRoot}/api/assignstudy/`,
      request
    )
      // .then(response => response.json())
      // .then(result => console.log(result))
      .catch(error => console.error('error', error));
  };

  return (
    <>
      <div className="assignto">
        <div className="assignto-body">
          {userData
            .sort((a, b) =>
              a.userName + a.userId > b.userName + b.userId ? 1 : -1
            )
            .map(item => {
              return (
                <>
                  <div className="selectuser">
                    <input
                      type="checkbox"
                      name={item.userId}
                      id={item.userId}
                      checked={assignedUserData.find(
                        user => user === item.userId
                      )}
                      onChange={() => {
                        if (
                          assignedUserData.find(user => user === item.userId)
                        ) {
                          setAssignedUserData(
                            assignedUserData.filter(
                              user => user !== item.userId
                            )
                          );
                        } else {
                          setAssignedUserData([
                            ...assignedUserData,
                            item.userId,
                          ]);
                        }
                      }}
                    />
                    <label htmlFor={item.userId}>{item.userName}</label>
                  </div>
                </>
              );
            })}
        </div>
        <div className="assignto-footer">
          <p
            style={{
              display: assignedUserData.length <= 10 ? 'none' : 'block',
            }}
          >
            {"Can't select more than 10 users"}
          </p>
          <button
            disabled={assignedUserData.length > 10}
            onClick={() => {
              if (assignedUserData.length <= 10) {
                studyData.map(async study => {
                  const postbody = {
                    studyInstanceUid: study.StudyInstanceUID,
                    users: assignedUserData,
                  };
                  await postUserData(postbody);
                });
                updateAssigntoOpen(false);
                var x = document.getElementById('snackbar');
                x.className = 'show';
                setTimeout(function() {
                  x.className = x.className.replace('show', '');
                }, 3000);
              }
            }}
          >
            Save
          </button>
        </div>
      </div>
    </>
  );
}

AssignToUserContent.propTypes = {
  studyData: PropTypes.array,
  updateAssigntoOpen: PropTypes.func,
};

export default AssignToUserContent;
