import React from 'react';
import './AttachDoc.css';
import { useState, useEffect } from 'react';
import Uploader from './Uploader';
import DocTable from './DocTable';
import axios from 'axios';
import PropTypes from 'prop-types';

function AttachDocument({ stdyUID }) {
  const BaseURL = window.config.servers.dicomWeb[0].wadoRoot;
  const url = `${BaseURL}doc/fetch/`;
  const studyInsUID = JSON.stringify({ studyinstanceUID: stdyUID });
  const [documentList, setdocumentList] = useState([]);
  const [deletedElement, setDeletedElement] = useState('');

  const idtoken = JSON.parse(
    sessionStorage.getItem(
      'oidc.user:' +
        window.config.oidc[0].authority +
        ':' +
        window.config.oidc[0].client_id
    )
  );
  const bearerToken = 'bearer ' + idtoken.id_token;

  /*
  to fetch data from backend
  */

  const fetchData = () => {
    axios
      .post(url, studyInsUID, { headers: { Authorization: bearerToken } })
      .then(res => setdocumentList(res.data))
      .catch(err => console.error(err));
  };
  useEffect(() => {
    fetchData();
  }, []);
  // fetchData();
  /*
  to download document
  */
  const downloadDoc = async index => {
    const data = '';
    const downloadURL = `${BaseURL}docdownload/`;
    await axios
      .post(downloadURL, data, {
        params: {
          studyinstanceuid: stdyUID,
          filename: documentList[index].fileName,
        },
        headers: { Authorization: bearerToken },
        responseType: 'blob',
      })
      .then(res => {
        const dfile = new Blob([res.data], {
          type: res.headers.get('Content-Type'),
        });
        window.open(URL.createObjectURL(dfile));
      })
      .catch(err => console.error(err));
  };

  /*
  to delete document
  */
  const deleteDoc = async fileName => {
    const deleteURL = `${BaseURL}docdelete/`;

    await axios
      .delete(deleteURL, {
        params: {
          studyinstanceuid: stdyUID,
          filename: fileName,
        },
        headers: { Authorization: bearerToken },
      })
      .then(res => {
        if (res.status === 200) {
          setDeletedElement(fileName);
          setTimeout(() => {
            setdocumentList(
              documentList.filter(document => document.fileName !== fileName)
            );
          }, 300);
        }
      })
      .catch(err => console.error(err));
  };

  return (
    <div className="container">
      <div className="subContainer">
        <label className="labelHead">Select Document file </label>
        <br></br>
        <div className="file-input">
          <Uploader studyUID={stdyUID} fetch={fetchData} />
        </div>

        <label className="labelHead">Documents</label>
        <div className="documentList">
          <DocTable
            downloadDoc={downloadDoc}
            documents={documentList}
            deleteDoc={deleteDoc}
            deletedElement={deletedElement}
          />
        </div>
      </div>
    </div>
  );
}

AttachDocument.propTypes = {
  stdyUID: PropTypes.string,
};

export default AttachDocument;
