import React, { useState } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { isInclusivelyBeforeDay } from 'react-dates';
import CustomDateRangePicker from './CustomDateRangePicker.js';
import { Icon } from './../../elements/Icon';
import { useTranslation } from 'react-i18next';
import ColumnResizer from 'react-table-column-resizer';
import Select from 'react-select';

const getDateEntry = (datePicked, rangeDatePicked) => {
  return rangeDatePicked || datePicked || null;
};

const getDateEntryFromRange = (today, numOfDays, edge = 'start') => {
  if (typeof numOfDays !== 'number') {
    return;
  }

  if (edge === 'end') {
    return today;
  } else {
    today.subtract(numOfDays, 'days');
  }
};

function TableSearchFilter(props) {
  const {
    meta,
    inputstyle,
    ChangeTableMetaColumnsize,
    studies,
    isLoading,
    // centerArray,
    values,
    onSort,
    onValueChange,
    sortFieldName,
    sortDirection,
    // TODO: Rename
    studyListDateFilterNumDays,
    handleContextMenuValues,
    checkUncheck,
    checkUncheckAll,
    // checkedRowData,
    handleDragStart,
    handleDragOver,
    handleOnDrop,
    handleDragEnter,
    dragOver,
    selectedPriority,
    SetSelectedPriority,
    PriorityOptions,
    reportStatusArry,
    reportStatuss,
    SetReportStatus,
    tempCenterArray,
    SetCenter,
    center,
    handleClick,
    modality,
    setModality,
    tempModalitie,
  } = props;
  const { studyDateTo, studyDateFrom } = values || {};
  const [focusedInput, setFocusedInput] = useState(null);
  const { t, ready: translationsAreReady } = useTranslation('Common');
  const sortIcons = ['', 'sort-down', 'sort-up'];
  const sortIconForSortField =
    sortDirection === 'asc' ? sortIcons[1] : sortIcons[2];

  const today = moment();
  const lastWeek = moment().subtract(7, 'day');
  const lastMonth = moment().subtract(1, 'month');

  const defaultStartDate = getDateEntryFromRange(
    today,
    studyListDateFilterNumDays,
    'start'
  );
  const defaultEndDate = getDateEntryFromRange(
    today,
    studyListDateFilterNumDays,
    'end'
  );

  const studyDatePresets = [
    {
      text: t('Today'),
      start: today,
      end: today,
    },
    {
      text: t('Last 7 days'),
      start: lastWeek,
      end: today,
    },
    {
      text: t('Last 30 days'),
      start: lastMonth,
      end: today,
    },
  ];
  //  Styling the <Select/> //
  const colourStyles = {
    container: styles => ({
      ...styles,
      // backgroundColor: 'red',
      padding: '0 !important',
    }),
    control: styles => ({
      ...styles,
      minHeight: '20px',
      height: '25px',
      cursor: 'pointer',
      width: '100%',
      backgroundColor: 'transparent',
      alignContent: 'center',
      alignItems: 'center',
      // paddingBottom: '10px',
      borderColor:
        styles.isFocused || styles.isSelected
          ? 'rgba(211, 211, 211, 0.677)'
          : '#111c1a',
      boxShadow:
        (styles.isFocused || styles.isSelected) &&
        'inset 0 1px 1px rgba(0,0,0,.075), 0 0 8px rgba(0, 0, 0, 0.479)',
    }),
    option: styles => ({
      ...styles,
      backgroundColor: '#12221f',
      '&:hover': {
        backgroundColor: 'rgb(63 130 99 / 38%)',
      },
      color: 'white',
      borderColor: '#12221f',
      overflow: 'hidden',
    }),
    multiValue: styles => ({
      ...styles,
      backgroundColor: 'transparent',
      color: 'white',
      minWidth: 'auto',
      justifyContent: 'space-between',
      margin: '0px',
    }),
    indicatorsContainer: styles => ({
      ...styles,
      // backgroundColor: 'tra',
      minHeight: '20px',
      // color: inputstyle === 'filterinputstyle' ? 'grey' : 'blue',
      height: '20px',
      padding: '0px',
    }),
    dropdownIndicator: styles => ({
      ...styles,
      backgroundColor: 'transparent',
      color: inputstyle === 'filterinputstyle' ? 'grey' : 'white',
      minHeight: '20px',
      height: '20px',
      padding: '0',
    }),
    clearIndicator: styles => ({
      ...styles,
      padding: '0px',
      color: inputstyle === 'filterinputstyle' ? 'grey' : 'white',
    }),
    valueContainer: styles => ({
      ...styles,
      minHeight: '20px',
      height: '20px',
      padding: '0px',
      paddingLeft: '6px',
      position: 'none',
      alignItems: 'start',
      flexWrap: 'nowrap',
      overflowX: 'auto',
    }),
    multiValueLabel: styles => ({
      ...styles,
      color: inputstyle === 'filterinputstyle' ? 'black' : 'white',
      // paddingLeft: '7px',
    }),
    indicatorSeparator: styles => ({
      ...styles,
      display: 'none',
    }),
    multiValueRemove: styles => ({
      ...styles,
      backgroundColor: 'transparent',
      paddingRight: '3px',
      paddingLeft: '0px',
    }),
    menu: styles => ({
      ...styles,
      backgroundColor: '#12221f',
      boxShadow:
        '0 0 0 1px hsl(168.75deg 30.77% 10.2%), 0 4px 11px hsl(168.75deg 30.77% 10.2%)',
    }),
    menuList: styles => ({
      ...styles,
      border: '1px solid',
      borderRadius: '4px',
      borderColor: '#e9f1eedb',
      color: styles.isFocused ? 'blue' : '',
    }),
  };

  // handling the dropedown menu of priority on each & multple selection
  const HandlePrioritySelection = selected => {
    var TempPriority = '';
    const All = selected && selected.find(e => e.value === '');
    if (!selected || selected.length === 0) {
      SetSelectedPriority([PriorityOptions[0]]);
    } else if (selected.length === PriorityOptions.length - 1) {
      SetSelectedPriority([PriorityOptions[0]]);
    } else if (selected[selected.length - 1].value !== '') {
      selected.map(item => {
        if (item.value !== '') TempPriority += item.value + ',';
      });
      TempPriority = TempPriority.substring(0, TempPriority.length - 1);
      SetSelectedPriority(selected.filter(e => e !== All));
    } else {
      TempPriority = '';
      SetSelectedPriority([All]);
    }
    onValueChange('ffinpriority', TempPriority);
  };

  // handling the dropedown menu of priority on each & multple selection
  const handleReportStatusSelection = selected => {
    var tempStatus = '';
    const All = selected && selected.find(e => e.value === '');
    if (!selected || selected.length === 0) {
      SetReportStatus([reportStatusArry[0]]);
    } else if (selected.length === reportStatusArry.length - 1) {
      SetReportStatus([reportStatusArry[0]]);
      tempStatus = '';
    } else if (selected[selected.length - 1].value !== '') {
      selected.map(item => {
        if (item.value !== '') tempStatus += item.value + ',';
      });
      tempStatus = tempStatus.substring(0, tempStatus.length - 1);
      SetReportStatus(selected.filter(e => e !== All));
    } else {
      tempStatus = '';
      SetReportStatus([All]);
    }
    onValueChange('ffinreportstatus', tempStatus);
  };

  // handling the dropedown menu of center selection on each selection
  const handlecenterSelection = selected => {
    var tempCenter = '';
    const All = selected && selected.find(item => item.value === '');
    const tempArray = selected && selected.filter(item => item.value !== '');

    if (!selected || selected.length === 0) {
      SetCenter([tempCenterArray[0]]);
    } else if (tempCenterArray.length - 1 === tempArray.length) {
      SetCenter([tempCenterArray[0]]);
    } else if (selected[selected.length - 1].value !== '') {
      selected.map(i => {
        if (i.value !== '') tempCenter += i.value + ',';
      });
      tempCenter = tempCenter.substring(0, tempCenter.length - 1);
      SetCenter(selected.filter(e => e !== All));
    } else {
      tempCenter = '';
      SetCenter([All]);
    }
    onValueChange('ffincenterid', tempCenter);
  };
  // handling the dropdown menu of modality selection on each selection

  const handlemodalitiesSelection = selected => {
    var tempModality = '';
    const All = selected && selected.find(item => item.value === 'All');
    const tempArray = selected && selected.filter(item => item.value !== 'All');
    if (!selected || selected.length === 0) {
      setModality([tempModalitie[0]]);
    } else if (tempModalitie.length - 1 === tempArray.length) {
      setModality([tempModalitie[0]]);
    } else if (selected[selected.length - 1].value !== 'All') {
      selected.map(item => {
        if (item.value !== 'All') tempModality += item.value + ',';
      });
      tempModality = tempModality.substring(0, tempModality.length - 1);
      setModality(selected.filter(e => e != All));
    } else {
      tempModality = '';
      setModality([All]);
    }
    onValueChange('modalities', tempModality);
  };

  return translationsAreReady
    ? []
        .concat(meta)
        .sort((a, b) => (a.order > b.order ? 1 : -1))
        .map((field, i) => {
          if (field.show) {
            const { displayText, fieldName, inputType } = field;
            const isSortField = sortFieldName === fieldName;
            const sortIcon = isSortField ? sortIconForSortField : sortIcons[0];
            return (
              <>
                {fieldName === 'Actions' ? (
                  <th
                    id={fieldName}
                    key={`${fieldName}-${i}`}
                    onContextMenu={event => {
                      event.preventDefault();
                      handleContextMenuValues(
                        true,
                        { x: event.pageX, y: event.pageY },
                        field
                      );
                    }}
                    onDragOver={e => handleDragOver(e)}
                    onDragEnd={e => handleOnDrop(e)}
                    onDragEnter={e => handleDragEnter(field, e)}
                    // dragOver={fieldName === dragOver}
                    style={
                      'Actions' === dragOver
                        ? {
                            borderRight: '3px solid rgb(95, 93, 93)',
                            width: `${field.minSize}px`,
                          }
                        : { width: `${field.minSize}px` }
                    }
                  >
                    <div className="table-header-cell">
                      <label
                        id={`filter-${fieldName}`}
                        // onClick={() => onSort(fieldName)}
                      >
                        {`${displayText}`}
                        {/* <Icon name={sortIcon} style={{ fontSize: '12px' }} /> */}
                      </label>
                      {studies.length > 0 && !isLoading && (
                        <input
                          type="checkbox"
                          id="selectAll"
                          name="selectAll"
                          checked={
                            /*checkedRowData.length > 0 || */ checkUncheckAll
                          }
                          // style={{
                          //  accentColor: checkUncheckAll ? '#12221f' : 'grey',
                          //  color: 'red !mportant',
                          // }}
                          onChange={() => {
                            checkUncheck();
                          }}
                        />
                      )}
                    </div>
                  </th>
                ) : (
                  <>
                    <th
                      key={`${fieldName}-${i}`}
                      id={fieldName}
                      onDragOver={e => handleDragOver(e)}
                      onDragEnd={e => handleOnDrop(e)}
                      onDragEnter={e => handleDragEnter(field, e)}
                      // dragOver={fieldName === dragOver}
                      style={
                        fieldName === dragOver
                          ? {
                              borderRight: '3px solid rgb(95, 93, 93)',
                              width: `${
                                field.size > 0 ? field.size : field.minSize
                              }px`,
                            }
                          : {
                              width: `${
                                field.size > 0 ? field.size : field.minSize
                              }px`,
                            }
                      }
                      onContextMenu={event => {
                        event.preventDefault();
                        const elements = document.querySelectorAll(
                          '.studylist-search'
                        );
                        let clkd = true;
                        for (let index = 0; index < elements.length; index++) {
                          if (elements[index].contains(event.target)) {
                            clkd = false;
                            break;
                          }
                        }
                        if (clkd) {
                          setFocusedInput(null);
                          handleContextMenuValues(
                            true,
                            { x: event.pageX, y: event.pageY },
                            field
                          );
                        } else {
                          handleClick(false);
                        }
                      }}
                    >
                      <div className="table-header-cell">
                        <label
                          htmlFor={`filter-${fieldName}`}
                          onClick={() => onSort(fieldName)}
                        >
                          <span
                            draggable
                            onDragStart={e => {
                              handleDragStart(field, e), setFocusedInput(null);
                            }}
                          >
                            {`${displayText} `}
                          </span>
                          {sortIcon !== '' && (
                            <Icon
                              name={sortIcon}
                              style={{ fontSize: '12px' }}
                            />
                          )}
                        </label>
                        {inputType === 'text' && (
                          <input
                            type="text"
                            id={`filter-${fieldName}`}
                            value={values[fieldName]}
                            className={
                              'form-control studylist-search ' + inputstyle
                            }
                            onChange={e => {
                              const value = e.target.value.replace(/\\/g, '');
                              onValueChange(fieldName, value.replace(/'/g, ''));
                            }}
                          />
                        )}
                        {inputType === 'dropdown' &&
                          fieldName === 'ffinpriority' && (
                            <Select
                              closeMenuOnSelect={false}
                              isMulti={true}
                              value={selectedPriority}
                              onChange={HandlePrioritySelection}
                              options={PriorityOptions}
                              isSearchable={false}
                              styles={{
                                ...colourStyles,
                              }}
                              isClearable={true}
                              maxMenuHeight="150px"
                              className={
                                'form-control studylist-search selectdropdown ' +
                                inputstyle
                              }
                              id={`filter-${fieldName}`}
                            ></Select>
                          )}
                        {inputType === 'dropdown' &&
                          fieldName === 'ffinreportstatus' && (
                            <Select
                              closeMenuOnSelect={false}
                              options={reportStatusArry}
                              isMulti={true}
                              value={reportStatuss}
                              styles={{
                                ...colourStyles,
                              }}
                              isClearable={true}
                              isSearchable={false}
                              onChange={handleReportStatusSelection}
                              className={
                                'form-control studylist-search selectdropdown ' +
                                inputstyle
                              }
                              id={`filter-${fieldName}`}
                            ></Select>
                          )}
                        {inputType === 'dropdown' &&
                          fieldName === 'modalities' && (
                            <Select
                              closeMenuOnSelect={false}
                              options={tempModalitie}
                              isMulti={true}
                              value={modality}
                              styles={{
                                ...colourStyles,
                              }}
                              isClearable={true}
                              isSearchable={false}
                              onChange={handlemodalitiesSelection}
                              className={
                                'form-control studylist-search selectdropdown ' +
                                inputstyle
                              }
                              id={`filter-${fieldName}`}
                            ></Select>
                          )}
                        {inputType === 'dropdown' &&
                          fieldName === 'ffincenterid' && (
                            <Select
                              closeMenuOnSelect={false}
                              options={tempCenterArray}
                              styles={{
                                ...colourStyles,
                              }}
                              onChange={handlecenterSelection}
                              value={center}
                              isMulti={true}
                              isSearchable={false}
                              isClearable={true}
                              // value = {}
                              className={
                                'form-control studylist-search selectdropdown ' +
                                inputstyle
                              }
                              id={`filter-${fieldName}`}
                              // onChange={e => {
                              //   onValueChange(fieldName, e.target.value);
                              // }}
                            ></Select>
                          )}
                        {inputType === 'date-range' && (
                          // https://github.com/airbnb/react-dates
                          <CustomDateRangePicker
                            // Required
                            startDate={getDateEntry(
                              studyDateFrom,
                              defaultStartDate
                            )}
                            startDateId="start-date"
                            endDate={getDateEntry(studyDateTo, defaultEndDate)}
                            endDateId="end-date"
                            // TODO: We need a dynamic way to determine which fields values to update
                            onDatesChange={({
                              startDate,
                              endDate,
                              preset = false,
                            }) => {
                              onValueChange('studyDateFrom', startDate);
                              onValueChange('studyDateTo', endDate);
                            }}
                            focusedInput={focusedInput}
                            onFocusChange={updatedVal => {
                              setFocusedInput(updatedVal);
                            }}
                            // Optional
                            numberOfMonths={1} // For med and small screens? 2 for large?
                            showClearDates={true}
                            anchorDirection="left"
                            presets={studyDatePresets}
                            hideKeyboardShortcutsPanel={true}
                            isOutsideRange={day =>
                              !isInclusivelyBeforeDay(day, moment())
                            }
                            className={
                              'dateRange studylist-search ' + inputstyle
                            }
                          />
                        )}
                      </div>
                    </th>
                    {/* Column Resizer */}
                    <ColumnResizer
                      id={`resizer-${fieldName}`}
                      className={
                        fieldName === dragOver
                          ? 'columnResizer dragOver'
                          : 'columnResizer'
                      }
                      minWidth={field.minSize}
                      defaultWidth={field.size}
                      resizeEnd={width => {
                        if (width > field.minSize)
                          ChangeTableMetaColumnsize(width, fieldName);
                      }}
                      // resizeStart={() => console.log('resize start')}
                    />
                  </>
                )}
              </>
            );
          }
        })
    : null;
}

TableSearchFilter.propTypes = {
  meta: PropTypes.arrayOf(
    PropTypes.shape({
      displayText: PropTypes.string.isRequired,
      fieldName: PropTypes.string.isRequired,
      inputType: PropTypes.oneOf(['text', 'date-range', 'dropdown']),
      size: PropTypes.number.isRequired,
    })
  ).isRequired,
  ChangeTableMetaColumnsize: PropTypes.func,
  inputstyle: PropTypes.string,
  centerArray: PropTypes.array,
  values: PropTypes.object.isRequired,
  onSort: PropTypes.func.isRequired,
  sortFieldName: PropTypes.string,
  sortDirection: PropTypes.oneOf([null, 'asc', 'desc']),
  selectedPriority: PropTypes.array,
  SetSelectedPriority: PropTypes.func,
  PriorityOptions: PropTypes.array,
  modalitiesArray: PropTypes.array,
};

TableSearchFilter.defaultProps = {};

export { TableSearchFilter };
export default TableSearchFilter;
