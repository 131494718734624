import PropTypes from 'prop-types';
import React, { useRef } from 'react';
import './ContextMenu.css';

const ContextMenu = ({ items, onClick, onClose }) => {
  const contextMenuRef = useRef(null);
  document.addEventListener('mousedown', event => {
    if (
      contextMenuRef.current &&
      !contextMenuRef.current.contains(event.target)
    ) {
      onClose();
    }
  });
  document.onmousewheel = () => {
    onClose();
  };
  return (
    <div
      className="ContextMenu"
      onContextMenu={e => e.preventDefault()}
      ref={contextMenuRef}
    >
      <ul>
        {items.map((item, index) => (
          <li key={index}>
            <button className="form-action" onClick={() => onClick(item)}>
              <span key={index}>{item.label}</span>
            </button>
          </li>
        ))}
      </ul>
    </div>
  );
};

ContextMenu.propTypes = {
  items: PropTypes.array.isRequired,
  onClick: PropTypes.func.isRequired,
  onClose: PropTypes.func,
};

export default ContextMenu;
