import cornerstone from 'cornerstone-core';
import React, { useRef, useEffect } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { ToolbarButton } from '@ohif/ui';
import { createSelector } from 'reselect';

const selectActiveViewportIndex = state => state.viewports.activeViewportIndex;
const selectViewportnumColumns = state => state.viewports.numColumns;
const selectViewportNumRows = state => state.viewports.numRows;
const selectStudyData = state => state.studies.studyData;

const stateSelector = createSelector(
  [selectActiveViewportIndex, selectViewportnumColumns, selectViewportNumRows, selectStudyData],
  (activeViewportIndex, numColumns, numRows, studyData) => {
    return {
      activeViewportIndex,
      numColumns,
      numRows,
      studyData
    };
  }
);
// Custom hook to get previous state values
const usePrevious = value => {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  }, [value]);
  return ref.current;
};
function IntelliLinkToolbarButton({ toolbarClickCallback, button, isActive }) {
  const { id, label, icon } = button;
  const { activeViewportIndex, numColumns, numRows, studyData } = useSelector(
    stateSelector
  );

  const prevIsActive = usePrevious(isActive);
  const prevActiveViewportIndex = usePrevious(activeViewportIndex);
  useEffect(() => {
    if (
      prevIsActive &&
      isActive &&
      activeViewportIndex != null &&
      prevActiveViewportIndex != null &&
      prevActiveViewportIndex != activeViewportIndex
    ) {
          toolbarClickCallback(button); // Disable actual sync
          toolbarClickCallback(button); // Activate new Sync
    }
  }, [
    activeViewportIndex,
    button,
    isActive,
    prevActiveViewportIndex,
    prevIsActive,
    toolbarClickCallback,
  ]);

  const studies = Object.values(studyData);
  const seriesLength = studies[0].series.length;
  const isVisible = (numRows > 1 || numColumns > 1) && (studies.length > 1 || seriesLength > 1);

  return (
    <React.Fragment>
      {isVisible && (
        <ToolbarButton
          key={id}
          label={label}
          icon={icon}
          onClick={evt => toolbarClickCallback(button, evt)}
          isActive={isActive}
        />
      )}
    </React.Fragment>
  );
}

IntelliLinkToolbarButton.propTypes = {
  toolbarClickCallback: PropTypes.func.isRequired,
  button: PropTypes.object.isRequired,
  isActive: PropTypes.bool,
};

export default IntelliLinkToolbarButton;
