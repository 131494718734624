import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { ToolbarButton } from '@ohif/ui';
import { utils, classes } from '@ohif/core';
import throttle from 'lodash.throttle';

const { studyMetadataManager } = utils;
const { StudyLoadingListener } = classes;

let isVisible = true;

const _isDisplaySetReconstructable = (
  viewportSpecificData = {},
  activeViewportIndex
) => {
  const [isStudyLoadedCompletely, setIsStudyLoadedCompletely] = useState(null);
  const { displaySetInstanceUID, StudyInstanceUID } = viewportSpecificData[
    activeViewportIndex
  ];

  const studies = studyMetadataManager.all();
  let study = undefined;
  if (StudyInstanceUID)
    study = studies.find(study => study.studyInstanceUID === StudyInstanceUID);
  let displaySet = undefined;
  if (displaySetInstanceUID && study)
    displaySet = study._displaySets.find(
      set => set.displaySetInstanceUID === displaySetInstanceUID
    );
  useEffect(() => {
    if (!viewportSpecificData[activeViewportIndex] || !study || !displaySet) {
      setIsStudyLoadedCompletely(null);
    } else if (displaySet && displaySet.isKeyImage) {
      setIsStudyLoadedCompletely(null);
    } else if (
      viewportSpecificData[activeViewportIndex] &&
      study &&
      displaySet &&
      !displaySet.isKeyImage
    ) {
      const onProgressChange = /* throttle( */ ({ detail }) => {
        const { progressId, progressData } = detail;
        if (`StackProgress:${displaySetInstanceUID}` === progressId) {
          const percent = progressData ? progressData.percentComplete : 0;
          if (percent === 100) {
            setIsStudyLoadedCompletely(true);
          } else {
            setIsStudyLoadedCompletely(false);
          }
        }
      }; //, 100);

      document.addEventListener(
        StudyLoadingListener.events.OnProgress,
        onProgressChange
      );

      return () => {
        document.removeEventListener(
          StudyLoadingListener.events.OnProgress,
          onProgressChange
        );
      };
    }
  }, [
    activeViewportIndex,
    displaySet,
    displaySetInstanceUID,
    study,
    viewportSpecificData,
    isStudyLoadedCompletely,
  ]);
  if (!viewportSpecificData[activeViewportIndex] || !study || !displaySet) {
    return false;
  }
  if (displaySet && displaySet.isKeyImage) {
    return false;
  }
  return isStudyLoadedCompletely === null
    ? displaySet.isReconstructable
    : displaySet.isReconstructable && isStudyLoadedCompletely;
};

function VTKMPRToolbarButton({
  parentContext,
  toolbarClickCallback,
  button,
  activeButtons,
  isActive,
  className,
}) {
  const { id, label, icon } = button;
  const { viewportSpecificData, activeViewportIndex } = useSelector(state => {
    const { viewports = {} } = state;
    const { viewportSpecificData, activeViewportIndex } = viewports;

    return {
      viewportSpecificData,
      activeViewportIndex,
    };
  });

  isVisible = _isDisplaySetReconstructable(
    viewportSpecificData,
    activeViewportIndex
  );

  return (
    <React.Fragment>
      {isVisible && (
        <ToolbarButton
          key={id}
          label={label}
          icon={icon}
          onClick={evt => toolbarClickCallback(button, evt)}
          isActive={isActive}
        />
      )}
    </React.Fragment>
  );
}

VTKMPRToolbarButton.propTypes = {
  parentContext: PropTypes.object.isRequired,
  toolbarClickCallback: PropTypes.func.isRequired,
  button: PropTypes.object.isRequired,
  activeButtons: PropTypes.array.isRequired,
  isActive: PropTypes.bool,
  className: PropTypes.string,
};

export default VTKMPRToolbarButton;
