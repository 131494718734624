import MenuItems from './DropedownComponentMenuItems';
import React from 'react';

const Dropdown = ({
  submenus,
  dropdown,
  depthLevel,
  dropdownSubmenuClassName,
}) => {
  depthLevel = depthLevel + 1;
  const dropdownClass = depthLevel > 1 ? dropdownSubmenuClassName : '';
  return (
    <ul className={`dropdown ${dropdownClass} ${dropdown ? 'show' : ''}`}>
      {submenus.map((submenu, index) => (
        <MenuItems
          items={submenu}
          key={index}
          depthLevel={depthLevel}
          dropdownSubmenuClassName={dropdownSubmenuClassName}
        />
      ))}
    </ul>
  );
};

export default Dropdown;
