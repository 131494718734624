import React from 'react';
import PropTypes from 'prop-types';

function StudyInfoContent({ study }) {
  const priority =
    study.ffinpriority === '1'
      ? 'High'
      : study.ffinpriority === '2'
      ? 'Medium'
      : study.ffinpriority === '3'
      ? 'Low'
      : '';

  return (
    <>
      <div className="studyinfo-body">
        <table className="patient-details">
          <tr>
            <th>Patient ID</th>
            <td>{study.PatientID}</td>
          </tr>
          <tr>
            <th>Patient Name</th>
            <td>{study.PatientName}</td>
          </tr>
          <tr>
            <th>Patient DOB</th>
            <td>{study.PatientBirthdate}</td>
          </tr>
          <tr>
            <th>Patient Sex</th>
            <td>{study.PatientSex}</td>
          </tr>
          <tr>
            <th>Accession No.</th>
            <td>{study.AccessionNumber}</td>
          </tr>
          <tr>
            <th>Insert Date</th>
            <td>{study.StudyDate}</td>
          </tr>
          <tr>
            <th>Patient Dicom Name</th>
            <td>{study.PatientName}</td>
          </tr>
          {/* <tr>
            <th>Institution Name</th>
            <td>FujiFilm</td>
          </tr> */}
          <tr>
            <th>Priority</th>
            <td>{priority}</td>
          </tr>
        </table>
        <table className="study-details">
          {/* <tr>
            <th>Study ID</th>
            <td>{study.StudyID}</td>
          </tr> */}
          <tr>
            <th>Study Date</th>
            <td>{study.StudyDate}</td>
          </tr>
          <tr>
            <th>Study Time</th>
            <td>{study.StudyTime}</td>
          </tr>
          <tr>
            <th>Study Description</th>
            <td>{study.StudyDescription}</td>
          </tr>
          <tr>
            <th>Modalities</th>
            <td>{study.modalities}</td>
          </tr>
          <tr>
            <th>Center</th>
            <td>{study.ffincenterid}</td>
          </tr>
          <tr>
            <th>Referring Doctor</th>
            <td>{study.referringPhysicianName}</td>
          </tr>
          <tr>
            <th>No. Of Series</th>
            <td>{study.numberOfStudyRelatedSeries}</td>
          </tr>
          <tr>
            <th>No. Of Instances</th>
            <td>{study.numberOfStudyRelatedInstances}</td>
          </tr>
        </table>
      </div>
    </>
  );
}
StudyInfoContent.propTypes = {
  study: PropTypes.object,
};

export default StudyInfoContent;
