import React from 'react';
import ReportHeader from './ReportHeader';
import ReportBanner from './ReportBanner';
import ReportSubHeader from './ReportSubHeader';
import { useLocation } from 'react-router-dom';

function ReportPage() {
  const location = useLocation();
  return (
    <div>
      <ReportHeader />
      <ReportSubHeader />
      <ReportBanner
        patientName={location.state.patientName}
        studyInstanceUID={location.state.studyInstanceUID}
      />
    </div>
  );
}

export default ReportPage;
