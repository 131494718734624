import React from 'react';
import './ReportBanner.css';

function ReportBanner(props) {
  return (
    <div className="reportBanner">
      <div className="centerPortion">
        <label className="bannerLabel">StudyInstanceUID: {props.studyInstanceUID}</label>
        <br></br>
        <label className="bannerLabel">PatientName: {props.patientName}</label>
      </div>
    </div>
  );
}

export default ReportBanner;
