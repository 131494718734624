import React from 'react';
import './ReportSubHeader.css';

function ReportSubHeader() {
  return (
    <div className="mainDiv">
      <h3 className="subHeader">Report</h3>
    </div>
  );
}

export default ReportSubHeader;
